import { State } from "./state";
import { GetterTree } from "vuex";

import { getError, getIsLoading, getDataItem, getSuccess, getDataList, getTotal, getId } from "@/store/Crud/functions/getters";

export const getTheoryQuestionExplanation = (state: State) => state.standardTheoryQuestionExplanation;
export const getTheoryQuestionExplanationError = (state: State) => state.standardTheoryQuestionExplanationError;
export const getTheoryQuestionExplanationIsLoading = (state: State) => state.standardTheoryQuestionExplanationIsLoading;

export const getUserLastModifiedExplanations = (state: State) => state.standardTheoryQuestionUserLastModifiedExplanations;
export const getUserLastModifiedExplanationsError = (state: State) => state.standardTheoryQuestionUserLastModifiedExplanationsError;
export const getUserLastModifiedExplanationsIsLoading = (state: State) => state.standardTheoryQuestionUserLastModifiedExplanationsIsLoading;
export const getUserLastExplanationSearches = (state: State) => state.standardTheoryQuestionUserLastSearches;

export default <GetterTree<State, any>>{
  getError,
  getIsLoading,
  getDataItem,
  getSuccess,
  getDataList,
  getTotal,
  getId,
  getTheoryQuestionExplanation,
  getTheoryQuestionExplanationError,
  getTheoryQuestionExplanationIsLoading,
  getUserLastModifiedExplanations,
  getUserLastModifiedExplanationsError,
  getUserLastModifiedExplanationsIsLoading,
  getUserLastExplanationSearches,
};
