import { State } from "./state";
import { MutationTree } from "vuex";
import {
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ITEM_LOADING,
  SET_DATA_ID,
} from "@/store/Crud/functions/mutations";

export function SET_THEORY_QUESTION_EXPLANATION_IS_LOADING(state: State, isLoading: any) {
  state.standardTheoryQuestionExplanationIsLoading = isLoading;
}

export function SET_THEORY_QUESTION_EXPLANATION_ERROR(state: State, error: string) {
  state.standardTheoryQuestionExplanationError = error;
}

export function SET_THEORY_QUESTION_EXPLANATION(state: State, standardTheoryQuestionExplanation: string) {
  state.standardTheoryQuestionExplanation = standardTheoryQuestionExplanation;
}

export function SET_USER_LAST_MODIFIED_EXPLANATIONS_IS_LOADING(state: State, isLoading: any) {
  state.standardTheoryQuestionUserLastModifiedExplanationsIsLoading = isLoading;
}

export function SET_USER_LAST_MODIFIED_EXPLANATIONS_ERROR(state: State, error: string) {
  state.standardTheoryQuestionUserLastModifiedExplanationsError = error;
}

export function SET_USER_LAST_MODIFIED_EXPLANATIONS(state: State, standardTheoryQuestionUserLastModifiedExplanations: any) {
  console.log("called mutation SET_USER_LAST_MODIFIED_EXPLANATIONS");
  console.log("with ", standardTheoryQuestionUserLastModifiedExplanations);
  state.standardTheoryQuestionUserLastModifiedExplanations = standardTheoryQuestionUserLastModifiedExplanations;
}

export function SET_NEW_EXPLANATION_SEARCH_TERM(state: State, term: any) {
  // Check if the term is an array
  if (Array.isArray(term)) {
    // If term is an array, set it directly as the value
    state.standardTheoryQuestionUserLastSearches = term.slice(0, 10);
  } else {
    if (state.standardTheoryQuestionUserLastSearches.length == 0) {
      state.standardTheoryQuestionUserLastSearches.unshift(term);
      return;
    }

    // Check if the last added term is the same as the current term
    if (state.standardTheoryQuestionUserLastSearches[0] !== term) {
      // Add the term to the beginning of the list
      state.standardTheoryQuestionUserLastSearches.unshift(term);

      // If the length exceeds 10, remove the last item (oldest)
      if (state.standardTheoryQuestionUserLastSearches.length > 10) {
        state.standardTheoryQuestionUserLastSearches.pop();
      }
    }
  }
}

export default <MutationTree<State>>{
  IS_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_DATA_ITEM,
  SET_DATA_LIST,
  SET_TOTAL,
  SET_DATA_ID,
  SET_DATA_ITEM_LOADING,
  SET_THEORY_QUESTION_EXPLANATION_IS_LOADING,
  SET_THEORY_QUESTION_EXPLANATION_ERROR,
  SET_THEORY_QUESTION_EXPLANATION,

  SET_USER_LAST_MODIFIED_EXPLANATIONS_IS_LOADING,
  SET_USER_LAST_MODIFIED_EXPLANATIONS_ERROR,
  SET_USER_LAST_MODIFIED_EXPLANATIONS,

  SET_NEW_EXPLANATION_SEARCH_TERM,
};
