import { IMainTab } from "@/interfaces/IMainTab";

export class State {
  tabs: { [key: string]: Array<IMainTab> } = {
    fsc: [],
    lms: [],
  };

  active: { [key: string]: IMainTab } = {
    fsc: { id: "", label: "", route: "", index: -1, trans: "", customLabel: false },
    lms: { id: "", label: "", route: "", index: -1, trans: "", customLabel: false },
  };

  customLabel?: string | null = null;
  tabsHistory: { [key: string]: Array<IMainTab> } = {
    fsc: [],
    lms: [],
  };
  activeContext = "fsc";
}
