export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const SET_DATA_ITEM = "SET_DATA_ITEM";
export const SET_DATA_LIST = "SET_DATA_LIST";
export const SET_TOTAL = "SET_TOTAL";
export const SET_THEORY_QUESTION_EXPLANATION = "SET_THEORY_QUESTION_EXPLANATION";
export const SET_THEORY_QUESTION_EXPLANATION_IS_LOADING = "SET_THEORY_QUESTION_EXPLANATION_IS_LOADING";
export const SET_THEORY_QUESTION_EXPLANATION_ERROR = "SET_THEORY_QUESTION_EXPLANATION_ERROR";

export const SET_USER_LAST_MODIFIED_EXPLANATIONS = "SET_USER_LAST_MODIFIED_EXPLANATIONS";
export const SET_USER_LAST_MODIFIED_EXPLANATIONS_IS_LOADING = "SET_USER_LAST_MODIFIED_EXPLANATIONS_IS_LOADING";
export const SET_USER_LAST_MODIFIED_EXPLANATIONS_ERROR = "SET_USER_LAST_MODIFIED_EXPLANATIONS_ERROR";

export const SET_NEW_EXPLANATION_SEARCH_TERM = "SET_NEW_EXPLANATION_SEARCH_TERM";
