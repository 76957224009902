


































import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { IMainTab } from "@/interfaces/IMainTab";
import { getViewLabel } from "@/utils/UrlUtils";
import { findById } from "@/utils/ArrayUtils";
import _ from "lodash";
import AbortButton from "@/components/Button/AbortButton.vue";
import { LMSSidebarItems, FSCSidebarItems, ExportSidebarItemPaths } from "@/constants/Sidebar";

const MainTabsModule = namespace("main-tabs");

@Component({
  components: {
    AbortButton,
  },
})
export default class MainTabs extends Vue {
  public name = "MainTabs";

  $refs!: {
    tabContent: HTMLElement;
    tabWrapper: HTMLElement;
    tabItem: Array<HTMLElement>;
  };

  @MainTabsModule.Action("setActive")
  private setActiveAction: any;

  @MainTabsModule.Action("setCustomLabel")
  private setCustomLabelAction: any;

  @MainTabsModule.Action("addTab")
  private addTabAction: any;

  @MainTabsModule.Action("removeTab")
  private removeTabAction: any;

  @MainTabsModule.Action("copyTabs")
  private copyTabsAction: any;

  @MainTabsModule.Getter("getTabs")
  private tabs: any;

  @MainTabsModule.Getter("getActive")
  private activeTab: any;

  @MainTabsModule.Getter("getCustomLabel")
  private customLabel: any;

  @MainTabsModule.Action("pushTabHistory")
  private pushTabHistory: any;

  @MainTabsModule.Action("popTabHistory")
  private popTabHistory: any;

  @MainTabsModule.Action("pushTabsHistory")
  private pushTabsHistory: any;

  @MainTabsModule.Getter("getTabsHistory")
  private tabsHistory!: Array<IMainTab>;

  @MainTabsModule.Action("removeTabHistoryById")
  private removeTabHistoryById: any;

  @MainTabsModule.Action("setActiveContext")
  private setActiveContext: any;

  @MainTabsModule.Getter("getActiveContext")
  private getActiveContextAction: any;

  @MainTabsModule.Action("setActiveTabsByContext")
  private setActiveTabsByContext: any;

  // Props
  protected activeEl: any = null;
  protected widths = {
    content: 0,
    wrapper: 0,
  };
  protected scrollOffset = 0;
  protected internalItemsLength = 0;
  protected isOverflowing = false;
  protected navStyle = {
    transform: "translateX(0px)",
  };
  protected existingTabIndex = -1;

  @Watch("$route", { immediate: true, deep: true })
  protected onUrlChange(): void {
    this.copyActiveContextFromStorage();
    this.copyTabsFromStorage();
    this.copyActiveTabsByContextFromStorage();

    if (this.activateExistingTab()) {
      this.findTabIndex();
      return;
    }

    const tab = this.constructTabFromRoute();
    this.addTabAction(tab);
    this.onChangeActiveTab(tab);
    this.findTabIndex();
  }

  public copyActiveContextFromStorage() {
    // can be optimized!
    // todo front
    const activeContext = sessionStorage.getItem("active-context");
    if (activeContext) {
      this.setActiveContext(activeContext);
    } else {
      this.setActiveContext("fsc"); // default
    }

    if (this.getActiveContextAction == "lms") {
      if (!ExportSidebarItemPaths(LMSSidebarItems).includes(this.$route.path) && ExportSidebarItemPaths(FSCSidebarItems).includes(this.$route.path)) {
        this.setActiveContext("fsc");
      }
    } else {
      if (
        !ExportSidebarItemPaths(FSCSidebarItems).includes(this.$route.path == "" ? "/" : this.$route.path) &&
        ExportSidebarItemPaths(LMSSidebarItems).includes(this.$route.path == "" ? "/" : this.$route.path)
      ) {
        this.setActiveContext("lms");
      }
    }
  }

  public copyActiveTabsByContextFromStorage() {
    const activeTabsByContext = sessionStorage.getItem("active-by-context");
    if (activeTabsByContext) {
      let activeTabs = JSON.parse(activeTabsByContext);
      if (activeTabs) {
        this.setActiveTabsByContext(JSON.parse(activeTabsByContext));
      }
    }
  }

  private copyTabsFromStorage(): void {
    if (!this.tabs.length) {
      const storedTabsJson = sessionStorage.getItem("tabs");
      if (storedTabsJson) {
        const storedTabs = JSON.parse(storedTabsJson);
        if (storedTabs) {
          this.copyTabsAction(storedTabs);
        }
      }
    }
  }

  private activateExistingTab(): IMainTab | undefined {
    const existingTab = findById(this.tabs, this.$route.fullPath);
    if (existingTab) {
      this.onChangeActiveTab(existingTab);
    }
    return existingTab;
  }

  private constructTabFromRoute(): IMainTab {
    let label = getViewLabel(this.$route, this.customLabel);
    let tab: IMainTab = {
      id: this.$route.fullPath,
      label: label,
      customLabel: Boolean(this.customLabel),
      route: this.$route.path,
      index: this.tabs.length,
      name: this.$route.name,
      trans: this.$route.meta ? this.$route.meta.trans : null,
    };
    if ("Home" === this.$route.name || "/" === this.$route.path || "/lms" === this.$route.path) {
      tab.home = true;
    }
    if (this.customLabel) {
      this.setCustomLabelAction(null);
    }
    return tab;
  }

  private onTabClick($event: any, tab: IMainTab, index: number): void {
    const target = $event.target;
    this.setActiveTabByIndex(index);
    if (
      (target as HTMLElement).nodeName === "svg" ||
      (target as HTMLElement).nodeName === "path" ||
      (target as HTMLElement).classList.contains("main-tab-close-btn")
    ) {
      this.closeTab(tab);
      return;
    }
    this.changeTab(tab);
  }

  private changeTab(tab: IMainTab): void {
    if (this.$route.fullPath !== tab.id) {
      this.$router.push(tab.id);
    }
  }

  private closeTab(tab: IMainTab): any {
    // Destroy vue instance
    this.$root.$emit("close-tab", tab);

    // Vuex: Remove from tabs stack
    this.removeTabAction(tab);

    // Vuex: Remove from tabsHistory stack
    this.removeTabHistoryById(tab);

    // Vuex: Get last active tab from tabsHistory
    const lastActiveTab = _.last(this.tabsHistory);

    if (lastActiveTab) {
      // If lastTab is not eq to current active tab navigate to lastActiveTab
      if (lastActiveTab.name != this.$route.name) {
        this.$router.push(lastActiveTab.id);
      }
    } else {
      this.pushDashboardHomeTabByContext();
    }
  }

  private pushDashboardHomeTabByContext(): void {
    if (this.getActiveContextAction == "lms") {
      this.$router.push({ name: "LMSDashboard" });
    } else {
      this.$router.push({ name: "Dashboard" });
    }
  }

  protected findTabIndex(): void {
    this.existingTabIndex = _.findIndex(this.tabs, (tab: any) => {
      return tab.route === this.$route.fullPath;
    });
    if (this.existingTabIndex !== -1) this.setActiveTabByIndex(this.existingTabIndex);
  }

  protected setActiveTabByIndex(index: number): void {
    this.$nextTick(() => {
      this.activeEl = this.$refs.tabItem[index];
      this.setWidths();
    });
  }

  protected nextHandle(): void {
    this.scrollTo("next");
  }

  protected prevHandle(): void {
    this.scrollTo("prev");
  }

  protected setWidths() {
    window.requestAnimationFrame(() => {
      const { tabContent, tabWrapper } = this.$refs;

      this.widths = {
        content: tabContent ? tabContent.clientWidth : 0,
        wrapper: tabWrapper ? tabWrapper.clientWidth : 0,
      };
      this.isOverflowing = this.widths.wrapper + 1 < this.widths.content;
      this.scrollIntoView();
    });
  }

  public mounted(): void {
    this.findTabIndex();

    this.$root.$on("on-close-tab", (tab: IMainTab) => {
      this.closeTab(tab);
    });

    // on resize screen calculate tabs position
    this.$nextTick(() => {
      const handleResize = _.throttle(() => {
        this.setWidths();
      });
      // @ts-ignore
      const observer = new ResizeObserver(handleResize);
      observer.observe(this.$refs.tabWrapper);
    });
  }

  public beforeUpdate() {
    this.internalItemsLength = (this.$children || []).length;
  }

  public updated() {
    if (this.internalItemsLength === (this.$children || []).length) return;
    this.setWidths();
  }

  protected get hasAffixes(): boolean {
    return this.isOverflowing || Math.abs(this.scrollOffset) > 0;
  }

  protected get hasNext(): boolean {
    if (!this.hasAffixes) return false;

    const { content, wrapper } = this.widths;

    // Check one scroll ahead to know the width of right-most item
    return content > Math.abs(this.scrollOffset) + wrapper;
  }

  protected get hasPrev(): boolean {
    return this.hasAffixes && this.scrollOffset !== 0;
  }

  protected scrollTo(location: "prev" | "next"): void {
    const { tabContent, tabWrapper } = this.$refs;

    this.scrollOffset = this.calculateNewOffset(
      location,
      {
        // Force reflow
        content: tabContent ? tabContent.clientWidth : 0,
        wrapper: tabWrapper ? tabWrapper.clientWidth : 0,
      },
      false,
      this.scrollOffset
    );
  }

  protected calculateNewOffset(direction: "prev" | "next", widths: any, rtl: boolean, currentScrollOffset: number) {
    const sign = rtl ? -1 : 1;
    const newAbsoluteOffset = sign * currentScrollOffset + (direction === "prev" ? -1 : 1) * widths.wrapper;

    return sign * Math.max(Math.min(newAbsoluteOffset, widths.content - widths.wrapper), 0);
  }

  protected scrollIntoView() {
    if (this.existingTabIndex === 0 || !this.isOverflowing) {
      this.scrollOffset = 0;
    } else if (this.isOverflowing) {
      this.scrollOffset = this.calculateCenteredOffset(this.activeEl, this.widths, false);
    }
  }

  @Watch("scrollOffset")
  protected scrollOffsetWatcher(scrollOffset: number): void {
    this.navStyle.transform = `translateX(-${scrollOffset}px)`;
  }

  private calculateUpdatedOffset(selectedElement: HTMLElement, widths: any, rtl: boolean, currentScrollOffset: number): number {
    const clientWidth = selectedElement.clientWidth;
    const offsetLeft = rtl ? widths.content - selectedElement.offsetLeft - clientWidth : selectedElement.offsetLeft;

    if (rtl) {
      currentScrollOffset = -currentScrollOffset;
    }

    const totalWidth = widths.wrapper + currentScrollOffset;
    const itemOffset = clientWidth + offsetLeft;
    const additionalOffset = clientWidth * 0.4;

    if (offsetLeft <= currentScrollOffset) {
      currentScrollOffset = Math.max(offsetLeft - additionalOffset, 0);
    } else if (totalWidth <= itemOffset) {
      currentScrollOffset = Math.min(currentScrollOffset - (totalWidth - itemOffset - additionalOffset), widths.content - widths.wrapper);
    }

    return rtl ? -currentScrollOffset : currentScrollOffset;
  }

  private calculateCenteredOffset(selectedElement: HTMLElement, widths: any, rtl: boolean): number {
    const { offsetLeft, clientWidth } = selectedElement;

    if (rtl) {
      const offsetCentered = widths.content - offsetLeft - clientWidth / 2 - widths.wrapper / 2;
      return -Math.min(widths.content - widths.wrapper, Math.max(0, offsetCentered));
    } else {
      const offsetCentered = offsetLeft + clientWidth / 2 - widths.wrapper / 2;
      return Math.min(widths.content - widths.wrapper, Math.max(0, offsetCentered));
    }
  }

  private onChangeActiveTab(tab: IMainTab): void {
    this.tabsHistoryFromSessionStorage();
    this.setActiveAction(tab);
    this.pushTabHistory(tab);
  }

  private tabsHistoryFromSessionStorage(): void {
    const tabsHistory = sessionStorage.getItem("tabs-history");
    if (!tabsHistory) return;
    this.pushTabsHistory(JSON.parse(tabsHistory));
  }

  public onCloseAllTabs(): void {
    // Vuex: tabs stack always must has one element "Dashboard"
    if (this.tabs.length > 1) {
      _.cloneDeep(this.tabs).forEach((tab: IMainTab) => {
        const isActive = tab.home;

        // if tab is not active remove from Stack and destroy the component
        if (!isActive) {
          // Vuex: Remove tab in tabs stack
          this.removeTabAction(tab);

          // Vuex: Remove tab in tabHistory stack
          this.removeTabHistoryById(tab);

          // Destroy the component
          this.$root.$emit("close-tab", tab);
        }
      });

      this.pushDashboardHomeTabByContext();
    }
  }
}
