




































import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import SidebarItem from "@/components/Sidebar/SidebarItem.vue";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import moment from "moment";
import { getBuildInfo } from "vue-cli-plugin-build-info/plugin";
import ActionButton from "@/components/Button/ActionButton.vue";
import SidebarItemNarrow from "@/components/Sidebar/SidebarItemNarrow.vue";
import {
  MODULE_ACCOUNTING,
  MODULE_AUTOMATIC_PAYMENTS,
  MODULE_COMMUNICATION,
  MODULE_COMPANY,
  MODULE_COST_BEARERS,
  MODULE_CUSTOMERS,
  MODULE_EXAM,
  MODULE_REPORTS,
  MODULE_TASK,
  MODULE_THEORY,
  MODULE_TIME_PLANNER,
} from "@/constants/Permissions";
import { namespace } from "vuex-class";

const DocumentModule = namespace("document");

@Component({
  components: { SidebarItem, ActionButton, SidebarItemNarrow },
})
export default class Sidebar extends Vue {
  public name = "Sidebar";

  @DocumentModule.Action("sendRouteInfo")
  public sendRouteInfo: any;

  public hideNames = true;
  public sidebarItemsStyle = "sidebar-items";
  public sidebarArrowIcon = ["fat", "angle-double-left"];
  public sidebarArrowTooltip = "Seitenleiste schließen";

  @Prop({ default: () => [] })
  public items: Array<any> | undefined;

  @Prop()
  public context: string | undefined;

  protected backendVersionResponse: { version: string; time: string } | null = null;

  public activeItem?: any = {};

  @Provide("activeMenuHandler")
  public activeMenuHandler(item: any): void {
    this.activeItem = item;
  }

  public mounted(): void {
    this.getBackendVersion();
  }

  public async getBackendVersion(): Promise<void> {
    if (process.env.VUE_APP_ENV === "development") return;
    const url = process.env.VUE_APP_BACKEND_BASE_URL + "/actuator/info";

    return await axios
      .get(url)
      .then((response: AxiosResponse) => {
        this.backendVersionResponse = response.data.build;
      })
      .catch((error: AxiosError) => {
        console.error(error, "error");
      });
  }

  public formatDateTime(date: string): string {
    return moment(date).format("HH:mm DD.MM.YYYY");
  }

  public get frontendVersion(): string {
    const { VERSION } = getBuildInfo();
    return `frontend ${VERSION}`;
  }

  public get frontendTime(): string {
    const { TIMESTAMP } = getBuildInfo();
    return `${this.formatDateTime(TIMESTAMP)}`;
  }

  public get backendVersion(): string {
    if (!this.backendVersionResponse) return "";
    return `backend ${this.backendVersionResponse.version}`;
  }

  public get backendTime(): string {
    if (!this.backendVersionResponse) return "";
    return `${this.formatDateTime(this.backendVersionResponse.time)}`;
  }

  public onHideNamesTrigger() {
    this.hideNames = !this.hideNames;
    if (this.hideNames) {
      this.sidebarItemsStyle = "sidebar-items";
      this.sidebarArrowIcon = ["fat", "angle-double-left"];
      this.sidebarArrowTooltip = "Seitenleiste schließen";
    } else {
      this.sidebarItemsStyle = "sidebarnr-narrow";
      this.sidebarArrowIcon = ["fat", "angle-double-right"];
      this.sidebarArrowTooltip = "Seitenleiste öffnen";
    }
    this.$emit("hide-names", this.hideNames);
  }

  public onLogoClick() {
    const screenName = this.$route.name ? this.$route.name : "";
    this.sendRouteInfo(screenName);
  }
}
