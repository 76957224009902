import { ActionTree } from "vuex";
import { State } from "./state";
import { create, filter, findOne, update, findAll } from "@/store/Crud/functions/actions";
import MessageDispatcher from "@/store/Crud/classes/MessageDispatcher";
import i18n from "@/i18n";
import axios from "@/utils/axios";
import {
  SET_THEORY_QUESTION_EXPLANATION,
  SET_THEORY_QUESTION_EXPLANATION_IS_LOADING,
  SET_THEORY_QUESTION_EXPLANATION_ERROR,
  SET_USER_LAST_MODIFIED_EXPLANATIONS_IS_LOADING,
  SET_USER_LAST_MODIFIED_EXPLANATIONS_ERROR,
  SET_USER_LAST_MODIFIED_EXPLANATIONS,
  SET_NEW_EXPLANATION_SEARCH_TERM,
} from "@/store/LMSExamQuestions/types";
import SessionStorageService from "@/services/SessionStorageService";
import { getUserLastExplanationSearches } from "@/store/LMSExamQuestions/getters";

const obfuscationKey = "tF4@zJq2!H5Lx$1bP9^W#dM&U7GpVnR*O8y(CZ)eX[aQ|k]{jD<>c,E%fYgh+r/T~S;";
const messageDispatcher = new MessageDispatcher();
export const explanation = async (store: any, theoryQuestionId: number): Promise<void> => {
  store.commit(SET_THEORY_QUESTION_EXPLANATION_IS_LOADING, true);
  return await axios
    .get(`/standard-question-explanation/${theoryQuestionId}`)
    .then(({ data }) => {
      store.commit(SET_THEORY_QUESTION_EXPLANATION_IS_LOADING, false);
      store.commit(SET_THEORY_QUESTION_EXPLANATION, prepare(data));
    })
    .catch(({ response }) => {
      store.commit(SET_THEORY_QUESTION_EXPLANATION_IS_LOADING, false);
      store.commit(SET_THEORY_QUESTION_EXPLANATION_ERROR, response.data);
      store.commit(SET_THEORY_QUESTION_EXPLANATION, ""); // set empty
    });
};

const atobToUint8Array = (base64: string): Uint8Array => {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

const prepare = (dt: any): string => {
  // to mak scrape process a bit harder
  // Step 1: Decode Base64 encoded obfuscated data
  const obfuscatedData = new TextDecoder("utf-8").decode(atobToUint8Array(dt));

  // Step 2: Deobfuscate using the key
  const base64Encoded = deOb(obfuscatedData, obfuscationKey);

  // Step 3: Decode Base64 encoded original string
  const decodedString = new TextDecoder("utf-8").decode(atobToUint8Array(base64Encoded));

  return decodedString;
};

const deOb = (data: string, key: string): string => {
  let result = "";
  for (let i = 0; i < data.length; i++) {
    // XOR each character with the key (cycling through key characters)
    const c = String.fromCharCode(data.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    result += c;
  }
  return result;
};

export const explanationUpsert = async (store: any, data: any): Promise<void> => {
  store.commit(SET_THEORY_QUESTION_EXPLANATION_IS_LOADING, true);
  return await axios
    .put(`/standard-question-explanation/${data.officialNumber}`, data.explanation, {
      headers: {
        "Content-Type": "text/plain",
      },
    })
    .then(() => {
      store.commit(SET_THEORY_QUESTION_EXPLANATION_IS_LOADING, false);
      store.commit(SET_THEORY_QUESTION_EXPLANATION, "");
      messageDispatcher.dispatchSuccessMessage(
        store,
        String(
          i18n.t("messages.save_success", {
            item: null,
          })
        )
      );
    })
    .catch(({ response }) => {
      store.commit(SET_THEORY_QUESTION_EXPLANATION_IS_LOADING, false);
      store.commit(SET_THEORY_QUESTION_EXPLANATION_ERROR, response.data);
    });
};

export const explanationReset = async (store: any, data: any): Promise<void> => {
  store.commit(SET_THEORY_QUESTION_EXPLANATION_IS_LOADING, true);
  return await axios
    .delete(`/standard-question-explanation/${data.officialNumber}`)
    .then(({ data }) => {
      store.commit(SET_THEORY_QUESTION_EXPLANATION_IS_LOADING, false);
      store.commit(SET_THEORY_QUESTION_EXPLANATION, data);
      messageDispatcher.dispatchSuccessMessage(
        store,
        String(
          i18n.t("messages.save_success", {
            item: null,
          })
        )
      );
    })
    .catch(({ response }) => {
      store.commit(SET_THEORY_QUESTION_EXPLANATION_IS_LOADING, false);
      store.commit(SET_THEORY_QUESTION_EXPLANATION_ERROR, response.data);
    });
};

export const explanationLastTenOfUser = async (store: any): Promise<void> => {
  store.commit(SET_USER_LAST_MODIFIED_EXPLANATIONS_IS_LOADING, true);
  return await axios
    .get(`/standard-question-explanation/last-modified`)
    .then(({ data }) => {
      console.log("commiting", data);
      store.commit(SET_USER_LAST_MODIFIED_EXPLANATIONS_IS_LOADING, false);
      store.commit(SET_USER_LAST_MODIFIED_EXPLANATIONS, data);
    })
    .catch(({ response }) => {
      store.commit(SET_USER_LAST_MODIFIED_EXPLANATIONS_IS_LOADING, false);
      store.commit(SET_USER_LAST_MODIFIED_EXPLANATIONS_ERROR, response.data);
      store.commit(SET_USER_LAST_MODIFIED_EXPLANATIONS, []); // set empty
    });
};

export const saveSearchTerm = async (store: any, text: string): Promise<void> => {
  store.commit(SET_NEW_EXPLANATION_SEARCH_TERM, text);
  sessionStorage.setItem("lms-explanation-search-terms", JSON.stringify(getUserLastExplanationSearches(store.state)));
};

export const loadSearchTerms = async (store: any): Promise<void> => {
  const terms = SessionStorageService.getFromLocalStorage("lms-explanation-search-terms");
  if (terms) {
    store.commit(SET_NEW_EXPLANATION_SEARCH_TERM, JSON.parse(terms));
  }
};

export default <ActionTree<State, any>>{
  filter,
  create,
  findOne,
  update,
  findAll,
  explanation,
  explanationUpsert,
  explanationReset,
  explanationLastTenOfUser,
  saveSearchTerm,
  loadSearchTerms,
};
