




































import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import FscCard from "@/components/Card/FscCard.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import ActionButton from "@/components/Button/ActionButton.vue";

@Component({
  components: { ActionButton, AbortButton, SaveButton, FscCard },
})
export default class SearchBar extends mixins() {
  public name = "SearchBar.vue";

  public questionSearchTerm: string | null = "";

  public cancel() {
    this.$emit("lms-search-submitted", null);
  }

  public search() {
    this.$emit("lms-search-submitted", this.questionSearchTerm, false);
  }

  public setSearchTerm(questionSearchTerm: string | null = "") {
    this.questionSearchTerm = questionSearchTerm;
  }
}
