





































import { Component, Vue, Watch } from "vue-property-decorator";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import MainSidebar from "@/components/MainSidebar/MainSidebar.vue";
import Navbar from "@/components/Navbar.vue";
import { namespace } from "vuex-class";
import RouterViewLayer from "@/RouterViewLayer.vue";
import { FSCSidebarItems, LMSSidebarItems, GetBgColorByPath, GetBgImageByPath } from "@/constants/Sidebar";
import SidebarItem from "@/components/Sidebar/SidebarItem.vue";
import SidebarItemNarrow from "@/components/Sidebar/SidebarItemNarrow.vue";

const SnackbarModule = namespace("snackbar");
const ModalModule = namespace("modal");
const CommunicationModule = namespace("communication");
const MainTabsModule = namespace("main-tabs");

import fscLogo from "@/assets/fsc_simple_logo.png";
import fscLogoNarrow from "@/assets/sidebar-narrow-fsc-icon.png";
import lmsLogo from "@/assets/drive-buzz-full-logo.png";
import lmsLogoNarrow from "@/assets/sidebar-narrow-lms-icon.png";
@Component({
  methods: {
    LMSSidebarItems() {
      return LMSSidebarItems;
    },
    FSCSidebarItems() {
      return FSCSidebarItems;
    },
  },
  components: { SidebarItemNarrow, SidebarItem, RouterViewLayer, Navbar, MainSidebar, Sidebar },
})
export default class Home extends Vue {
  public name = "Dashboard";

  public fscLogo: string = fscLogo;
  public fscLogoNarrow: string = fscLogoNarrow;
  public lmsLogo: string = lmsLogo;
  public lmsLogoNarrow: string = lmsLogoNarrow;

  public tabs: Array<number> = [];
  public tabCounter = 0;
  protected pdfModalId = "pdf-modal";
  protected srcPdf = null;

  protected bgColor = "black";
  protected bgImg = null;

  protected dynamicStyle = {};

  @SnackbarModule.Getter("getMessage")
  private snackbarMessage: any;

  @SnackbarModule.Action("resetSnackbar")
  private resetSnackbar: any;

  @ModalModule.Action("setStatus")
  private setModalStatus: any;

  @ModalModule.Action("setContent")
  private setModalContent: any;

  @ModalModule.Getter("getStatus")
  private modalStatus: any;

  @ModalModule.Getter("getContent")
  private modalContent: any;

  @MainTabsModule.Action("setActiveContext")
  private setActiveContext: any;

  @MainTabsModule.Getter("getActiveContext")
  private getActiveContextAction: any;

  @MainTabsModule.Getter("getActiveTabsByContext")
  private getActiveTabsByContext: any;

  @CommunicationModule.Action("notifications")
  public notifications!: () => void;

  protected wrapperWidth = "wrapper size-full-screen";

  @Watch("snackbarMessage")
  private onSnackbarStatus(snackbarMessage: any): void {
    if (snackbarMessage) {
      switch (snackbarMessage.type) {
        case "success":
          this.$toasted.success(snackbarMessage.text);
          break;
        case "error":
          this.$toasted.error(snackbarMessage.text);
          break;
        default:
          this.$toasted.info(snackbarMessage.text);
          break;
      }
      this.resetSnackbar();
    }
  }

  @Watch("modalStatus")
  protected getStatusWatcher(status: boolean): void {
    if (status) {
      this.$bvModal.show(this.pdfModalId);
    }
  }

  protected onHideModal(): void {
    this.setModalStatus(false);
    this.setModalContent(null);
  }

  public mounted(): void {
    if (this.getActiveContextAction == "fsc") {
      this.fscSidebarOn = true;
      this.lmsSidebarOn = false;
    } else {
      this.fscSidebarOn = false;
      this.lmsSidebarOn = true;
    }
    this.notifications();
    this.setBackground(this.getActiveContextAction, this.$route.fullPath);
  }

  private setBackground(context: string, path: string): void {
    this.bgColor = GetBgColorByPath(context, path);
    this.bgImg = GetBgImageByPath(context, path);

    if (this.bgImg) {
      // priority on image
      this.dynamicStyle = {
        backgroundImage: this.bgImg,
        backgroundSize: "cover",
        backgroundPosition: "center 5%",
      };
      return;
    }
    // fall back color
    if (this.bgColor) {
      this.dynamicStyle = { backgroundColor: this.bgColor };

      return;
    }
  }

  public sidebarSizeChange(hideNames: any) {
    if (hideNames) {
      this.wrapperWidth = "wrapper size-full-screen";
    } else {
      this.wrapperWidth = "wrapper-narrow size-full-screen";
    }
  }

  public sidebarShow() {
    this.wrapperWidth = "wrapper size-full-screen";
  }

  public fscSidebarOn = true;
  public lmsSidebarOn = false;

  public setFSCSidebarOn(): void {
    this.fscSidebarOn = true;
    this.lmsSidebarOn = false;
    this.setActiveContext("fsc");
    this.setOnContextChange("fsc");
  }

  public setLMSSidebarOn(): void {
    this.fscSidebarOn = false;
    this.lmsSidebarOn = true;
    this.setActiveContext("lms");
    this.setOnContextChange("lms");
  }

  public setOnContextChange(cont: string) {
    if (cont in this.getActiveTabsByContext) {
      let contextActiveTab = this.getActiveTabsByContext[cont];
      if (contextActiveTab.route) {
        this.$router.push(contextActiveTab.route);
      } else {
        // in case user first time opens browser and no active tab in cache we activate the below:
        if (cont == "lms") {
          this.$router.push({ name: "LMSDashboard" });
        } else {
          this.$router.push({ name: "Dashboard" });
        }
      }
    }
  }
  @Watch("$route.fullPath")
  protected onChangePath(): void {
    this.setBackground(this.getActiveContextAction, this.$route.fullPath);
  }
}
