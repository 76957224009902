import { GetterTree } from "vuex";
import { State } from "./state";
import { IMainTab } from "@/interfaces/IMainTab";

export function getTabs(state: State): Array<IMainTab> {
  return state.tabs[state.activeContext];
}

export function getActive(state: State): any {
  return state.active[state.activeContext];
}

export function getActiveTabsByContext(state: State): any {
  return state.active;
}

export function getActiveContext(state: State): any {
  return state.activeContext;
}

export function getCustomLabel(state: State): any {
  return state.customLabel;
}

export function getTabsHistory(state: State): Array<IMainTab> {
  return state.tabsHistory[state.activeContext];
}

export default <GetterTree<State, any>>{
  getActive,
  getActiveContext,
  getTabs,
  getCustomLabel,
  getTabsHistory,
  getActiveTabsByContext
};
