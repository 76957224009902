






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MainSidebarItem extends Vue {
  public name = "MainSidebarItem";

  @Prop({ type: String })
  public liClasses!: string;
}
