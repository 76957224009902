import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";
import { IMainTab } from "@/interfaces/IMainTab";

export function setActiveContext(store: ActionContext<State, any>, context: string) {
  store.commit("SET_ACTIVE_CONTEXT", context);
}

export function setActive(store: ActionContext<State, any>, tab: IMainTab) {
  store.commit("SET_ACTIVE", tab);
}

export function setActiveTabsByContext(store: ActionContext<State, any>, activeTabsByContext: { [key: string]: IMainTab }) {
  store.commit("SET_ACTIVE_TABS_BY_CONTEXT", activeTabsByContext);
}

export function addTab(store: ActionContext<State, any>, tab: IMainTab) {
  store.commit("ADD_TAB", tab);
}

export function removeTab(store: ActionContext<State, any>, tab: IMainTab) {
  store.commit("REMOVE_TAB", tab);
}

export function removeTabById(store: ActionContext<State, any>, tab: IMainTab) {
  store.commit("REMOVE_TAB_BY_ID", tab);
}

export function setCustomLabel(store: ActionContext<State, any>, customLabel: string | null) {
  store.commit("SET_CUSTOM_LABEL", customLabel);
}

export function copyTabs(store: ActionContext<State, any>, tabs: Array<any>): void {
  store.commit("COPY_TABS", tabs);
}

export function pushTabHistory(store: ActionContext<State, any>, tab: IMainTab): void {
  store.commit("PUSH_TAB_HISTORY", tab);
}

export function pushTabsHistory(store: ActionContext<State, any>, tabs: Array<IMainTab>): void {
  store.commit("PUSH_TABS_HISTORY", tabs);
}

export function popTabHistory(store: ActionContext<State, any>): void {
  store.commit("POP_TAB_HISTORY");
}

export function removeTabHistoryById(store: ActionContext<State, any>, tab: IMainTab): void {
  store.commit("REMOVE_TAB_HISTORY_BY_ID", tab);
}

export default <ActionTree<State, any>>{
  setActive,
  setActiveTabsByContext,
  setActiveContext,
  addTab,
  removeTab,
  removeTabById,
  setCustomLabel,
  copyTabs,
  pushTabHistory,
  popTabHistory,
  pushTabsHistory,
  removeTabHistoryById,
};
